import React from 'react'
import Client from 'shopify-buy';

const defaultState = {
  prevPath: '/',
  navIsVisible: true,
  showNav: () => {},
  hideNav: () => {},
  updatePrevPath: () => {},
  addVariantToCart:() => {},
  updateQuantityInCart:() => {},
  removeLineItemInCart:() => {},
  checkout: null
}

const ThemeContext = React.createContext(defaultState)

class ThemeProvider extends React.Component {
  state = {
    navIsVisible: true
  }
  showNav = () => {
    this.setState({ navIsVisible: true })
  }
  hideNav = () => {
    this.setState({ navIsVisible: false })
  }
  updatePrevPath = (path) => {
    this.setState({ prevPath: path })
  }
  componentWillMount() {
    this.client = Client.buildClient({
      storefrontAccessToken: '92996cd0c9ed4adabd4e2a7a3c7bb913',
      domain: 'booklover-project.myshopify.com'
    });
    this.updateCart()
  }

  async updateCart() {
    const checkout =  await this.getCheckout()
    window.xyz = checkout
    return checkout
  }

  async getCheckout(retry = true, force = false) {
    if (!force && this.state.checkout) {
      return this.state.checkout
    }
    if (typeof window === 'undefined') {
      return
    }
    if (window.localStorage && window.localStorage.getItem) {
      const storedToken = window.localStorage.getItem('shopify_checkout_token')
      if (storedToken) {
        try{
          const checkout = await this.client.checkout.fetch(storedToken)
          this.setState({
            checkout
          });
          return checkout
        } catch(e) {
          window.localStorage.removeItem('shopify_checkout_token')
          if (retry) {
            return await this.getCheckoutToken(false)
          }
        }
      }
    }
    try {
      const checkout = await this.client.checkout.create()
      if (window.localStorage && window.localStorage.setItem) {
        window.localStorage.setItem('shopify_checkout_token', checkout.id)
      }
      this.setState({
        checkout: checkout,
      });
      return checkout
    } catch(e) {
      if (retry) {
        return this.getCheckoutToken(false)
      }
    }
  }

  addVariantToCart(variantId, quantity, cb = null){
    this.setState({
      isCartOpen: true,
    });

    const lineItemsToAdd = [{variantId, quantity: parseInt(quantity, 10)}]
    const checkoutId = this.state.checkout.id

    return this.client.checkout.addLineItems(checkoutId, lineItemsToAdd).then(res => {
      this.setState({
        checkout: res,
      });
      if (cb && cb.call) {
        cb(res)
      }
    });
  }

  updateQuantityInCart(lineItemId, quantity) {
    const checkoutId = this.state.checkout.id
    const lineItemsToUpdate = [{id: lineItemId, quantity: parseInt(quantity, 10)}]

    return this.client.checkout.updateLineItems(checkoutId, lineItemsToUpdate).then(res => {
      this.setState({
        checkout: res,
      });
    });
  }

  removeLineItemInCart(lineItemId) {
    const checkoutId = this.state.checkout.id

    return this.client.checkout.removeLineItems(checkoutId, [lineItemId]).then(res => {
      this.setState({
        checkout: res,
      });
    });
  }

  render() {
    const { children } = this.props
    const { prevPath, navIsVisible, checkout } = this.state
    return (
      <ThemeContext.Provider
        value={{
          prevPath,
          navIsVisible,
          checkout,
          showNav: this.showNav.bind(this),
          hideNav: this.hideNav.bind(this),
          updatePrevPath: this.updatePrevPath.bind(this),
          addVariantToCart: this.addVariantToCart.bind(this),
          updateQuantityInCart: this.updateQuantityInCart.bind(this),
          removeLineItemInCart: this.removeLineItemInCart.bind(this),
        }}
      >
        {children}
      </ThemeContext.Provider>
    )
  }
}
export default ThemeContext
export { ThemeProvider }
